<template>
  <div class="grid">
    <div class="col-12">
      <div class="font-medium text-3xl text-900 mb-3">
        <span class="text-primary text-5xl">/</span> Período
      </div>
      <div class="text-500 mb-5">Período</div>

      <div class="card">
        <div
          class="
            flex flex-column
            mb-4
            md:flex-row md:justify-content-between md:align-items-center
          "
        >
          <div class="p-fluid mt-2 w-full md:mt-0 md:w-5">
            <Calendar
              id="dataInicial"
              class="flex py-3"
              v-model="form.dataInicial"
              dateFormat="dd/mm/yy"
              :showIcon="true"
              placeholder="Data Inicial"
            />
          </div>
          <div class="p-fluid mt-2 w-full md:mt-0 md:w-5">
            <Calendar
              id="dataFinal"
              class="flex py-3"
              v-model="form.dataFinal"
              dateFormat="dd/mm/yy"
              :showIcon="true"
              placeholder="Data Final"
            />
          </div>
        </div>

        <Toast />

        <DataTable
          :value="rows"
          :row-hover="true"
          editMode="cell"
          responsiveLayout="scroll"
          rowGroupMode="subheader"
          groupRowsBy="seqPeriodo"
          sortField="seqPeriodo"
        >
          <template #empty> Nenhum registro encontrado. </template>

          <template #loading> Carregando registros. Aguarde ... </template>

          <template #groupheader="slotProps">
            <span class="image-text"
              >Período {{ slotProps.data.seqPeriodo }}</span
            >
          </template>

          <Column field="seqPeriodo" header="Período"></Column>

          <Column field="description">
            <template #body="slotProps">
              <b>{{ slotProps.data.description }}</b>
            </template>
          </Column>

          <Column header="Domingo">
            <template #body="slotProps">
              <InputMask
                class="p-inputtext-sm"
                :style="styleInputTable"
                v-model.trim="horariosEditing[slotProps.data.seqPeriodo][1][slotProps.data.name]"
                :mask="slotProps.data.mask"
              />
            </template>
          </Column>

          <Column header="Segunda-feira">
            <template #body="slotProps">
              <InputMask
                class="p-inputtext-sm"
                :style="styleInputTable"
                v-model="horariosEditing[slotProps.data.seqPeriodo][2][slotProps.data.name]"
                :mask="slotProps.data.mask"
              />
            </template>
          </Column>

          <Column header="Terça-feira">
            <template #body="slotProps">
              <InputMask
                class="p-inputtext-sm"
                :style="styleInputTable"
                v-model="horariosEditing[slotProps.data.seqPeriodo][3][slotProps.data.name]"
                :mask="slotProps.data.mask"
              />
            </template>
          </Column>

          <Column header="Quarta-feira">
            <template #body="slotProps">
              <InputMask
                class="p-inputtext-sm"
                :style="styleInputTable"
                v-model="horariosEditing[slotProps.data.seqPeriodo][4][slotProps.data.name]"
                :mask="slotProps.data.mask"
              />
            </template>
          </Column>

          <Column header="Quinta-feira">
            <template #body="slotProps">
              <InputMask
                class="p-inputtext-sm"
                :style="styleInputTable"
                v-model="horariosEditing[slotProps.data.seqPeriodo][5][slotProps.data.name]"
                :mask="slotProps.data.mask"
              />
            </template>
          </Column>

          <Column header="Sexta-feira">
            <template #body="slotProps">
              <InputMask
                class="p-inputtext-sm"
                :style="styleInputTable"
                v-model="horariosEditing[slotProps.data.seqPeriodo][6][slotProps.data.name]"
                :mask="slotProps.data.mask"
              />
            </template>
          </Column>

          <Column header="Sábado">
            <template #body="slotProps">
              <InputMask
                class="p-inputtext-sm"
                :style="styleInputTable"
                v-model="horariosEditing[slotProps.data.seqPeriodo][7][slotProps.data.name]"
                :mask="slotProps.data.mask"
              />
            </template>
          </Column>
        </DataTable>

        <div class="flex mt-5">
          <Button
            label="Novo período"
            icon="pi pi-plus"
            class="p-button-text w-3"
            @click="adicionarPeriodo()"
          />
        </div>

        <div class="flex mt-5">
          <Button
            label="Salvar"
            icon="pi pi-check"
            class="p-button-primary w-6"
            @click="save"
          />
          <Button
            label="Voltar"
            icon="pi pi-times"
            class="p-button-text w-3 ml-auto"
            @click="$router.go(-1)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AreaMedicaService from "../../services/AreaMedicaService";

export default {
  data() {
    return {
      form: {
        horarios: [],
      },
      horariosEditing: {
        // Período
        '1': {
          // Dias da semana
          1: {}, 2: {}, 3: {}, 4: {}, 5: {}, 6: {}, 7: {}
        }
      },
      rows: [],
      modeloRows: [
        { name: "horarioInicial", description: "Horário inicial", mask: '99:99', seqPeriodo: 0 },
        { name: "horarioFinal", description: "Horário final", mask: '99:99', seqPeriodo: 0 },
        { name: "atendimentosSimultaneos", description: "Atendimentos simultâneos", mask: '9?9', seqPeriodo: 0 },
      ],
      styleInputTable: 'width: 60px'
    };
  },
  mounted() {
    this.$service = new AreaMedicaService(
      `/agendas/${this.$route.params.id}/periodos`
    );
    this.load();
  },
  methods: {
    async load() {
      if (this.$route.params.idPeriodo) {
        const { data } = await this.$service.findById(
          this.$route.params.idPeriodo
        );
        this.form = data;
      }
      this.transformHorariosToHorariosEditing();

      const lastSeqPeriodo = Math.max(...this.form.horarios.map(h => h.seqPeriodo).concat(0))

      for (let seqPeriodo = 1; seqPeriodo <= lastSeqPeriodo; seqPeriodo++) {
        this.adicionarRowPeriodo();
      }
    },
    adicionarRowPeriodo() {
      const lastSeqPeriodo = Math.max(...this.rows.map(row => row.seqPeriodo).concat(0))
      const seqPeriodo = lastSeqPeriodo + 1
      const modeloRowsDeep = JSON.parse(JSON.stringify(this.modeloRows))

      modeloRowsDeep.forEach(row => (row.seqPeriodo = seqPeriodo))

      this.rows = this.rows.concat(modeloRowsDeep);
    },
    adicionarPeriodo() {
      const lastSeqPeriodo = Math.max(...this.rows.map(row => row.seqPeriodo).concat(0))
      this.form.horarios.push({seqPeriodo: lastSeqPeriodo + 1})
      this.transformHorariosToHorariosEditing();
      this.adicionarRowPeriodo();
    },
    transformHorariosToHorariosEditing() {
      const horariosDeep = JSON.parse(JSON.stringify(this.form.horarios))
      const lastSeqPeriodo = Math.max(...horariosDeep.map(horario => horario.seqPeriodo).concat(0))

      for (let seqPeriodo = 1; seqPeriodo <= lastSeqPeriodo; seqPeriodo++) {
        for (let diaSemana = 1; diaSemana <= 7; diaSemana++) {
          const horarioNestePeriodoNesteDia = horariosDeep.filter(h => h.seqPeriodo === seqPeriodo && h.diaSemana === diaSemana)
          this.horariosEditing[seqPeriodo] = this.horariosEditing[seqPeriodo] || { 1: {}, 2: {}, 3: {}, 4: {}, 5: {}, 6: {}, 7: {} }
          this.horariosEditing[seqPeriodo][diaSemana] = horarioNestePeriodoNesteDia[0] || {}
        }
      }
    },
    transformHorariosEditingToHorarios() {
      const horariosEditingDeep = JSON.parse(JSON.stringify(this.horariosEditing))
      const horarios = []
      
      for (const seqPeriodo in horariosEditingDeep) {
        if (Object.hasOwnProperty.call(horariosEditingDeep, seqPeriodo)) {
          for (let diaSemana = 1; diaSemana <= 7; diaSemana++) {
            horariosEditingDeep[seqPeriodo][diaSemana].seqPeriodo = seqPeriodo
            horariosEditingDeep[seqPeriodo][diaSemana].diaSemana = diaSemana 
            horarios.push(horariosEditingDeep[seqPeriodo][diaSemana])           
          }          
        }
      }

      this.form.horarios = horarios
    },
    removerStringVazias() {
      this.form.horarios?.forEach(horario => {
        horario.horarioInicial = horario.horarioInicial || null
        horario.horarioFinal = horario.horarioFinal || null
        horario.atendimentosSimultaneos = horario.atendimentosSimultaneos || null
      })
    },
    async save() {
      this.submitted = true;
      try {
        this.transformHorariosEditingToHorarios()
        this.removerStringVazias()
        await this.$service.save(this.form);
        this.$toast.add({
          severity: "success",
          summary: "Salvo com sucesso",
          life: 3000,
        });
        this.$router.go(-1);
      } catch (err) {
        this.$toast.add({
          severity: "error",
          summary: "Problemas ao salvar informações!",
          life: 3000,
        });
      }
    },
  },
};
</script>
